import { encode } from "querystring";

import { Classes, FormGroup } from "@blueprintjs/core";
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE_JSON,
  OrgId,
  withAttribution,
} from "@hex/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { HexButton, HexCallout, HexInputGroup } from "../../hex-components";
import { useAttribution } from "../../hooks/useAttribution";
import { Routes, useQueryParams } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { isMagicLinkEnabled } from "../../util/data";
import { createRequestHeaders } from "../../util/headers";
import { logErrorMsg } from "../../util/logging";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";
import { Link } from "../common/DocsLink";
import { LightbulbIcon, SparklesIcon } from "../icons/CustomIcons";

const Form = styled.form`
  margin: 0;

  .${Classes.LABEL} > .${Classes.TEXT_MUTED} {
    margin-left: 3px;

    color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LoginButton = styled(HexButton)`
  > .${Classes.BUTTON_TEXT} {
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};
  }
`;

const EmailFormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AdminWarningDiv = styled(HexCallout)`
  margin-bottom: 16px;

  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const ManualPasswordCallout = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export interface EmailLoginProps {
  authQueryParams: Record<string, string>;
  displayName?: string;
  onComplete: () => void;
  onSubmit: () => void;
  orgId?: OrgId;
  mfaCode?: string;
  ssoEnforced: boolean;
  embedded: boolean;
}

export const EmailLogin: React.ComponentType<EmailLoginProps> = React.memo(
  function EmailLogin({
    authQueryParams,
    displayName,
    embedded,
    onComplete,
    onSubmit: onSubmit_,
    orgId,
    ssoEnforced,
  }) {
    const attribution = useAttribution();
    const queryParams = useQueryParams();
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    // If magic link is not enabled passowrd is always required for email login.
    const passwordRequiredDefault =
      !!queryParams.get("password") || !isMagicLinkEnabled;
    // When a password fails, we include "password=true" in the queryparameters,
    // so we leverage that to ensure we continue to expose the password field
    // for retries. Additionally, we should show the password field if magic
    // link is disabled, as there will be no other means to auth.
    const [passwordRequired, setPasswordRequired] = useState<boolean>(
      passwordRequiredDefault,
    );
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [mfacode, setMfaCode] = useState<string>("");
    const passwordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!passwordRequiredDefault && passwordRequired) {
        passwordRef.current?.focus();
      }
    }, [passwordRequired, passwordRequiredDefault, passwordRef]);

    const baseFormUrl =
      orgId != null ? `/auth/${orgId}/local` : `/auth-all/local`;

    const updateEmail = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        setEmail(evt.currentTarget.value);
      },
      [],
    );

    const updatePassword = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        setPassword(evt.currentTarget.value);
      },
      [],
    );

    const updateMfaCode = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        setMfaCode(evt.currentTarget.value);
      },
      [],
    );

    const onSubmit = useCallback(
      async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit_();
        let res;
        let res_json;
        try {
          res = await fetch(
            withAttribution(
              `/auth-all/magic?${encode(authQueryParams)}`,
              attribution,
            ),
            {
              method: `POST`,
              body: JSON.stringify(
                await maybeIncludeTelemetry<unknown>(
                  {
                    email,
                    orgId,
                    embedded,
                  },
                  isTelemetryEnabled,
                  telemetryTimeoutMs,
                ),
              ),
              headers: {
                [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE_JSON,
                ...createRequestHeaders(),
              },
            },
          );
          res_json = await res.json();
        } catch (err) {
          res_json = { success: false, error: err };
        }

        const { code, error, success } = res_json;
        if (success) {
          const redirectTo = queryParams.get("redirectTo");
          window.location.href = `/login${Routes.MAGIC_LINK_SENT.getUrl({
            attribution,
            code,
            email,
            params: redirectTo ? { redirectTo } : {},
          })}`;
        } else if (error != null && error.isPasswordRequired) {
          setPasswordRequired(true);
          onComplete();
        } else {
          window.location.href = withAttribution(
            "/login?failed=true",
            attribution,
          );
        }
      },
      [
        attribution,
        email,
        queryParams,
        orgId,
        authQueryParams,
        embedded,
        isTelemetryEnabled,
        onSubmit_,
        onComplete,
        telemetryTimeoutMs,
      ],
    );

    const onSubmitPassword = useCallback(
      async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit_();
        try {
          const res = await fetch(
            withAttribution(
              `${baseFormUrl}?${encode(authQueryParams)}`,
              attribution,
            ),
            {
              method: `POST`,
              body: JSON.stringify(
                await maybeIncludeTelemetry<unknown>(
                  {
                    username: email,
                    password,
                    mfacode,
                  },
                  isTelemetryEnabled,
                  telemetryTimeoutMs,
                ),
              ),
              headers: {
                [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE_JSON,
                ...createRequestHeaders(),
              },
            },
          );
          if (res.redirected) {
            window.location.href = withAttribution(res.url, attribution);
          } else {
            onComplete();
          }
        } catch (err) {
          logErrorMsg(err, "Failure on passworded login");
          window.location.href = withAttribution(
            `/login?failed=true&password=true`,
            attribution,
          );
        }
      },
      [
        email,
        authQueryParams,
        baseFormUrl,
        mfacode,
        password,
        isTelemetryEnabled,
        onSubmit_,
        onComplete,
        telemetryTimeoutMs,
        attribution,
      ],
    );

    return (
      <EmailFormSection>
        {ssoEnforced && (
          <AdminWarningDiv $size="small" icon={<LightbulbIcon />}>
            Only {displayName} admins can log in via password.
          </AdminWarningDiv>
        )}
        <Form onSubmit={!passwordRequired ? onSubmit : onSubmitPassword}>
          <FormGroup label="Email" labelFor="username" labelInfo="(required)">
            <HexInputGroup
              data-cy={CyData.USERNAME}
              id="username"
              name="username"
              placeholder="you@example.com"
              required={true}
              type="email"
              value={email}
              onChange={updateEmail}
            />
          </FormGroup>
          {passwordRequired && (
            <FormGroup
              label="Password"
              labelFor="password"
              labelInfo="(required)"
            >
              <HexInputGroup
                data-cy={CyData.PASSWORD}
                id="password"
                inputRef={passwordRef}
                name="password"
                placeholder="**********"
                required={true}
                type="password"
                value={password}
                onChange={updatePassword}
              />
            </FormGroup>
          )}
          {passwordRequired && email === "support@hex.tech" && (
            <FormGroup
              label="MFA Code"
              labelFor="mfacode"
              labelInfo="(required)"
            >
              <HexInputGroup
                autoComplete="one-time-code"
                id="token"
                inputMode="numeric"
                name="token"
                pattern="[0-9]*"
                placeholder="**********"
                required={true}
                type="text"
                value={mfacode}
                onChange={updateMfaCode}
              />
            </FormGroup>
          )}
          <Action>
            <LoginButton
              className={Classes.FILL}
              data-cy={CyData.LOGIN}
              type="submit"
            >
              {passwordRequired ? "Log in" : "Continue"}
            </LoginButton>
            {!passwordRequired && (
              <ManualPasswordCallout>
                <SparklesIcon />
                <div>
                  We&apos;ll email you a link to log in, or you can{" "}
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <Link onClick={() => setPasswordRequired(true)}>
                    log in with a password
                  </Link>{" "}
                  instead.
                </div>
              </ManualPasswordCallout>
            )}
          </Action>
        </Form>
      </EmailFormSection>
    );
  },
);
