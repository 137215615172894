import { OrgId } from "@hex/common";
import React, { useEffect, useState } from "react";

import { useOrgDetailsQuery } from "../../route/LoginRoute.generated";
import { Routes } from "../../route/routes";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";

export interface EmbeddedRedirectProps {
  orgId: OrgId;
  authQueryParams: Record<string, string>;
}

export const EmbeddedRedirect: React.ComponentType<EmbeddedRedirectProps> =
  React.memo(function EmbeddedRedirect({ authQueryParams, orgId }) {
    const [ssoUrl, setSsoUrl] = useState<string>();
    const { data } = useOrgDetailsQuery({
      variables: { orgId },
    });
    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    useEffect(() => {
      void maybeIncludeTelemetry(
        authQueryParams,
        isTelemetryEnabled,
        telemetryTimeoutMs,
      ).then((params) => {
        setSsoUrl(
          Routes.SSO_AUTH.getUrl({
            orgId,
            params,
          }),
        );
      });
    }, [orgId, authQueryParams, isTelemetryEnabled, telemetryTimeoutMs]);

    const useEmbeddedRedirect =
      data?.publicOrgDetails?.ssoEnforced &&
      data.publicOrgDetails.allowsIframing;

    useEffect(() => {
      if (useEmbeddedRedirect && ssoUrl != null) {
        window.location.href = ssoUrl;
      }
    }, [ssoUrl, useEmbeddedRedirect]);

    return null;
  });
